export class LoginRequest {
    constructor(
        Login: string,
        Senha: string,
        Recaptchatoken: string,
    ) {
        this.Login = Login;
        this.Senha = Senha;
        this.RecaptchaToken = Recaptchatoken
    }

    Login: string;
    Senha: string;
    RecaptchaToken: string;
}
