export const environment = {
    production: false,
    urlApiDashboard:  'https://ccoqas.zadsp.com.br/api',
    urlApiPushNotification: 'https://notificationqas.zadsp.com.br',
    rotaCadastrarPush: '/Notification/schedule',
    rotaListarPush: '/Notification/{page}/{count}/{notificationTypeId}',
    rotaExcluirPush: '/Notification/{idPushNotification}',
    filialId: 38,
    urlBlobFiles: 'https://zadspqa.blob.core.windows.net',
    nomeArquivoComerciosCoordenadas: 'comercios-coordenadas-mapas.json',
    recaptchaSiteKey: '6LccB7AqAAAAAPDwz3OA90j0VzKquwJMobM-i1OL'
  };
